<template>
    <setting-layout title="Delete Account" >
        <v-container>
            <v-row>
                <text-field
                    type="password"
                    :error-messages="errors.password"
                    placeholder="Type your password"
                    v-model="model"
                />
            </v-row>
            <v-row justify="center" class="pt-7 pl-7 pr-7">
                <gradient-button block @click="del">DELETE</gradient-button>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import {mapActions} from "vuex";

export default {
    name: "DeleteAccount",
    data: () => ({
        model: '',
        errors: {}
    }),
    methods: {
        del() {
            this.deleteUser(this.model).then(() => {
                this.$window.location = '/'
            }).catch((e) => {
                if (typeof e.response !== 'undefined') {
                    this.errors = e.response.data.errors
                }
            })
        },
        ...mapActions(['deleteUser'])
    },
    mounted() {

    },
    components: {GradientButton, TextField, SettingLayout}
}
</script>

<style scoped>

</style>
